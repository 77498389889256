import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { SanitySlice, SEO } from "~components";
import { useBackgroundColorStore } from "~store";

/** ============================================================================
 * @page
 * Static page routes @ /*.
 */
const Page = ({ data: staticData }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { sanityPage } = staticData;
  const { pagebuilder, backgroundColor } = sanityPage;

  const slices = pagebuilder?.slices || [];

  const setBackgroundColor = useBackgroundColorStore(
    (state) => state.setBackgroundColor
  );

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    setBackgroundColor(backgroundColor?.hex || `#FFFFFF`);
  }, [backgroundColor, setBackgroundColor]);

  // ---------------------------------------------------------------------------
  // render

  return (
    slices?.[0] &&
    slices.map((slice) => <SanitySlice key={slice._key} data={slice} />)
  );
};

export default Page;

export const Head = ({ location, data: staticData }) => (
  <SEO
    location={location}
    site={staticData?.site || {}}
    sanitySeo={staticData?.sanityPage?.seo}
  />
);

export const query = graphql`
  query Page($id: String!) {
    site {
      ...SiteFragment
    }

    sanityPage(id: { eq: $id }) {
      title
      slug {
        current
      }

      backgroundColor {
        hex
      }

      fontColor {
        hex
      }

      pagebuilder {
        slices {
          ...AllEventsFragment
          ...BannerFragment
          ...CardGridFragment
          ...ContactFormFragment
          ...ExpandableTextArticleFragment
          ...ExpanderGridFragment
          ...LinkFlexFragment
          ...MarqueeFragment
          ...PageHeadingFragment
          ...TextArticleFragment
        }
      }

      seo {
        title
        description
        keywords
        image {
          asset {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
